.navbar_neutral_100 {
    @apply bg-neutral-100;
}
.navbar_neutral {
    @apply bg-neutral-900;
}
.navbar_violet {
    @apply bg-violet-900;
}

.navbar_dark .navbar-nav .nav-item > .nav-link {
    @apply hover:decoration-white
        xl:text-white !important;
}
.navbar_dark .navbar-nav .nav-item > .nav-link .icon svg {
    @apply xl:stroke-white !important;
}
.navbar_dark .button {
    @apply sm:text-white;
}

.navbar_sticky {
    @apply sticky top-0 z-[99];
}
.navbar_sticky:not(.navbar_neutral, .navbar_violet) {
    @apply bg-white;
}

.navbar .container {
    @apply relative flex items-center justify-between;
}

.navbar-button-group > .divider,
.navbar-button-group > .button_demo {
    @apply max-md:hidden;
}

.navbar .divider {
    @apply my-2;
}
