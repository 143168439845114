.error-page-wrap {
    @apply bg-gray-blue-800;
}

.error-page {
    @apply flex
     flex-col
     justify-between;
}

.error-page h1 {
    @apply text-neutral-100 text-34p mb-20p leading-44p font-bold;
}

.error-page p {
    @apply text-neutral-250 text-16p max-w-[600px] leading-24p text-center font-normal;
}

.error-page a.link {
    @apply !ml-0 text-13p font-normal;
}

.error-page a.button {
    @apply text-14p leading-20p font-medium text-white;
}

