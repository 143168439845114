.navbar-nav {
    @apply flex mr-auto;
}

.navbar-nav .nav-item:not(:last-child) {
    @apply mr-8;
}

.navbar-nav .nav-item:first-child {
    @apply list-item xl:hidden;
}

.navbar-nav .nav-item > .nav-link {
    @apply text-base font-[600] leading-5 flex items-center gap-x-1 text-neutral-900
        xl:underline xl:underline-offset-2 xl:decoration-transparent xl:hover:decoration-neutral-900
        hover:text-neutral-900
        focus:text-neutral-900
        active:text-neutral-900;
}
.navbar-nav .nav-item > .nav-link .icon {
    @apply transition-transform duration-300
}
.navbar-nav .nav-item > .nav-link .icon svg {
    @apply stroke-neutral-900;
}

@media screen and (min-width: 1280px) {
    .navbar-nav .nav-item > .nav-link:hover .icon {
        @apply -rotate-180;
    }

    .navbar-nav .nav-item > .nav-link:hover + .navbar-subnav,
    .navbar-subnav:hover {
        @apply block
        xl:grid xl:grid-cols-[304px_2fr] xl:gap-x-4;
    }
}

.navbar-subnav_show {
    @apply !block
    xl:grid xl:grid-cols-[304px_2fr] xl:gap-x-4;
}
