.footer-copyright {
    @apply pt-4 mb-10 border-t md:py-2 md:mb-0 border-neutral-300;
}

.footer-copyright .divider {
    @apply mx-2 my-1;
}

.footer-copyright .nav-link {
    @apply text-xs;
}
