.navbar-collapse {
    @apply relative flex items-center;
}

.navbar-collapse > .button-group {
    @apply px-5 pb-7 xl:hidden;
}

@media only screen and (max-width: 1279px) {
    .navbar-collapse .navbar-nav .list_subnav .list__item .nav-link {
        @apply pl-41p;
    }

    .navbar-collapse {
        @apply fixed top-0 left-full max-w-[460px] w-full h-full flex-col items-start overflow-y-auto bg-white z-50;
    }
    .navbar-collapse.collapsed {
        @apply -translate-x-full;
    }

    .navbar-collapse .navbar-nav {
        @apply flex-col w-full px-5 pb-6;
    }
    .navbar-collapse .navbar-nav .nav-item:not(:last-child) {
        @apply m-0 border-b border-b-neutral-300 !important;
    }
    .navbar-collapse .navbar-nav .nav-link {
        @apply flex items-center justify-between w-full p-4 text-xl font-bold leading-8;
    }
    /* .navbar-collapse .navbar-nav .nav-link .icon svg {
        @apply dark:stroke-neutral-900;
    } */

    .navbar-collapse .navbar-subnav {
        @apply relative top-0 left-0 w-full p-0 border-0 shadow-none;
    }
    .navbar-collapse .navbar-subnav .list {
        @apply mb-4 gap-y-0;
    }

    .navbar-collapse .navbar-subnav > .info,
    .navbar-collapse .navbar-subnav .icon,
    .navbar-collapse .navbar-subnav .paragraph {
        @apply hidden;
    }
}
