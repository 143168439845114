.mail-response {
    @apply w-[280px] bg-white fixed py-10 px-12 z-50 top-1/2 left-1/2 text-center rounded-lg -translate-x-1/2 -translate-y-1/2
        md:px-6
        md:w-[440px]
        lg:w-[448px];
}

.mail-response img {
    @apply mx-auto;
}

.mail-response .heading {
    @apply mb-2;
}

.mail-response .button {
    @apply min-w-[160px] mt-8;
}