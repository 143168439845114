.blockquote {
    @apply flex flex-col p-10 rounded-3xl gap-y-6;
}

.blockquote > .blockquote__body {
    @apply mb-auto;
}

.blockquote_bg-violet-100 {
    @apply bg-violet-100;
}
.blockquote_bg-red-100 {
    @apply bg-red-100;
}
.blockquote_bg-yellow-100 {
    @apply bg-yellow-100;
}
.blockquote_bg-green-100 {
    @apply bg-green-100;
}