.section__headline {
    @apply mb-12;
}

.section__headline_center {
    @apply text-center;
}

.section__headline_no-margin {
    @apply m-0;
}

.section__headline_w_85 {
    @apply lg:w-[85%] lg:mx-auto;
}
.section__headline_w_75 {
    @apply lg:w-[75%] lg:mx-auto;
}
.section__headline_w_70 {
    @apply lg:w-[70%] lg:mx-auto;
}
.section__headline_w_50 {
    @apply lg:w-[50%] lg:mx-auto;
}
.section__headline .paragraph {
    @apply mt-4;
}
.section__headline .button {
    @apply mt-10;
}
