.radials {
    @apply absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 rounded-full;
    background: radial-gradient(50% 50% at 50% 50%, #E6E6FF 57.6%, rgba(255, 255, 255, 0.54) 94.48%);
}

.radials.radials_gradient_first {
    background: radial-gradient(50% 50% at 50% 50%, #F0F1FF 57.6%, rgba(255, 255, 255, 0.54) 94.48%);
}
.radials.radials_gradient_third {
    background: radial-gradient(50% 50% at 50% 50%, #DEDFFF 57.6%, rgba(255, 255, 255, 0.54) 94.48%);
}

.radials.radials_dark {
    @apply bg-none bg-primary-neutral;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 4px 2.75px 0px rgba(0, 12, 28, 0.0403),
        0px 8px 6px 0px rgba(0, 12, 28, 0.0577),
        0px 17px 14px 0px rgba(0, 12, 28, 0.0723),
        0px 36px 29px 0px rgba(0, 12, 28, 0.0897),
        0px 100px 80px 0px rgba(0, 12, 28, 0.13);
}

.radials.radials_white {
    @apply bg-none;
    box-shadow: 0px 3.5px 3px 0px rgba(35, 37, 87, 0.01),
        0px 9px 7px 0px rgba(35, 37, 87, 0.0133),
        0px 18px 14px 0px rgba(35, 37, 87, 0.018),
        0px 36px 30px 0px rgba(35, 37, 87, 0.02),
        0px -12px 30px 0px rgba(35, 37, 87, 0.02);
}