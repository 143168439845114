.pricing-toggle {
    @apply relative flex flex-col md:flex-row p-1 mx-auto bg-white rounded-[1.875rem] md:rounded-[5.125rem] md:w-fit shadow-[0_4px_13px_0px_rgba(19,34,64,0.08)];
    flex-wrap: nowrap;
    flex-basis: 100%;
}

.pricing-toggle .button {
    @apply z-0 px-12 py-3.5 text-lg font-normal text-neutral-900 relative;
}
.pricing-toggle .button:before {
    @apply content-[''] absolute bg-neutral-400 top-0 left-[1.875rem] h-[1px] w-[calc(100%-3.75rem)] z-0;
    @apply md:w-[1px] md:h-[50%] md:left-[0px] md:top-[25%];
}
.pricing-toggle_css-pointer .button:after {
    @apply content-[''] absolute w-full rounded-[1.875rem] h-full bg-violet-700 left-0 top-0 z-[-1] opacity-0;
}
.pricing-toggle .button:first-child {
    @apply before:invisible;
}
.pricing-toggle .button.active + .button {
    @apply before:invisible;
}

.pricing-toggle .button.active {
    @apply text-white before:invisible after:opacity-100  after:transition-opacity after:duration-300;
}

.pricing-toggle_css-pointer .button.active {
    @apply after:opacity-100  after:transition-opacity after:duration-300;
}

.pricing-toggle img {
    @apply absolute -right-14 -top-10;
}

@media only screen and (max-width: 479px) {
    .pricing-toggle img {
        @apply -right-3 -top-12 -rotate-[35deg] w-[70px];
    }
}
