.pricing__option {
    @apply flex flex-col w-full p-6 text-center bg-white rounded-lg xl:px-10 py-6;
}

.pricing__option_recommended {
    @apply p-6;
}

.pricing__option > .info {
    align-items: center;
}
