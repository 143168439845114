.range-input {
    @apply flex justify-center flex-wrap max-w-full pr-4;
}
.range-input__track-wrapper {
    @apply h-9 flex w-full;
}
.range-input__track {
    @apply h-1 w-full rounded-[4px] self-center;
}
.range-input__thumb {
    @apply h-7 w-7 rounded-full bg-white flex justify-center items-center;
    box-shadow: 0 6px 13px 0 #0000001F, 0 0.5px 4px 0 #0000001F;
}
.range-input__label {
    @apply absolute bottom-9 text-white bg-neutral-900 rounded-[4px] p-2;
}
.range-input__label:after {
    @apply content-[''] absolute top-[100%] left-1/2 -ml-[5px] border-[5px] border-solid border-t-neutral-900;
}
.range-input__range-labels-wrapper {
    @apply text-neutral-600 w-full flex justify-between;
}