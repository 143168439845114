.radio {
    @apply w-4 h-4 border border-neutral-700 rounded-full bg-white bg-no-repeat bg-center bg-[length:8px_15px] appearance-none
        hover:border-2 hover:border-violet-500 hover:shadow-[0_1px_3px_0_rgba(127,132,238,0.36)]
        focus:border-2 focus:border-violet-500
        active:border-2 active:border-violet-700 active:bg-violet-100
        disabled:border-neutral-400 disabled:bg-neutral-100;
}

.radio:checked {
    @apply w-4 h-4 border-violet-700 bg-radio-checked
        hover:border-2 hover:border-violet-500 hover:bg-[2px_center]
        focus:border-2 focus:border-violet-500 focus:bg-[2px_center]
        active:border-2 active:bg-violet-100 active:border-violet-900
        disabled:border-neutral-400 disabled:bg-neutral-100;
}