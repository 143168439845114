.alternatives-pricing-comparison {
    width: auto;
    margin: 24px -17.5px;
}

.alternatives-pricing-comparison td {
    border: 1px solid var(--color-neutral-450);
    width: 50%;
    padding: 24px 6px;
    color: var(--color-neutral-900);
    font-size: 14px;
}

.alternatives-pricing-comparison th {
    border: 1px solid var(--color-neutral-450);
    width: 50%;
    padding: 26px 0;
    background-color: var(--color-neutral-100);
}

.alternatives-pricing-comparison tr > :is(th:is(:first-child, :last-child), td:is(:first-child, :last-child)) {
    border-left: none;
}

.alternatives-pricing-comparison > thead img {
    height: 28px;
    margin: 0 auto;
}

.alternatives-pricing-comparison__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.alternatives-pricing-comparison__block h2 {
    font-size: 1.75rem;
    line-height: 2.5rem;
    font-weight: 800;
}

.alternatives-pricing-comparison__block > *:nth-child(-n+2) {
    margin-bottom: 16px;
}

.alternatives-pricing-comparison__block-icon-with-text {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin: 24px 0;
}

.alternatives-pricing-comparison__block img,
.alternatives-pricing-comparison__block-icon-with-text img {
    height: 20px;
    width: 20px;
}

@media (min-width: 375px) {
    .alternatives-pricing-comparison > thead img {
        height: 36px;
    }
}

@media (min-width: 480px) {
    .alternatives-pricing-comparison {
        width: 100%;
        margin: 32px 0;
    }

    .alternatives-pricing-comparison tr > :is(th:is(:first-child, :last-child), td:is(:first-child, :last-child)) {
        border-left: 1px solid var(--color-neutral-450);
    }

    .alternatives-pricing-comparison > thead img {
        height: 40px;
    }

    .alternatives-pricing-comparison td {
        padding: 32px 16px;
    }

    .alternatives-pricing-comparison__block-icon-with-text {
        flex-direction: row;
    }
}

@media (min-width: 768px) {
    .alternatives-pricing-comparison {
        margin: 48px 0;
    }

    .alternatives-pricing-comparison > thead img {
        height: 48px;
    }

    .alternatives-pricing-comparison td {
        padding: 40px 48px;
    }
}

@media (min-width: 1024px) {
    .alternatives-pricing-comparison__block h2{
        font-size: 2.625rem;
        line-height: 3.5rem;
    }
}

@media (min-width: 1280px) {

}
