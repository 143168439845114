.alternatives-header {
    --width-for-margin-left: 100%;
    background-color: var(--color-neutral-900);
    width: 100vw;
    margin-left: calc(((100vw - var(--width-for-margin-left)) / 2) / -1);
}

.alternatives-header__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 32px;
}

.alternatives-header__inner-vs {
    display: flex;
    align-items: center;
    padding-top: 32px;
}

.alternatives-header__inner-vs-square-rounded {
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
}

.alternatives-header__inner-vs .heading_1 {
    margin: 0 16px;
}

.alternatives-header__inner-vs-square-rounded > img {
    height: 45px;
    width: auto;
}

@media (min-width: 480px) {

    .alternatives-header__inner-vs-square-rounded {
        width: 80px;
        height: 80px;
    }

    .alternatives-header__inner-vs-square-rounded > img {
        height: 50px;
        width: auto;
    }
}

@media (min-width: 768px) {

    .alternatives-header__inner {
        flex-direction: row;
        padding-top: 0;
        padding-bottom: 0;
        height: 304px;
        align-items: center;
    }

    .alternatives-header__inner > h1 {
        margin-right: 48px;
    }

    .alternatives-header__inner-vs {
        padding-top: 0;
    }

    .alternatives-header__inner-vs-square-rounded {
        width: 104px;
        height: 104px;
        border-radius: 24px;
    }

    .alternatives-header__inner-vs-square-rounded > img {
        height: 65px;
        width: auto;
    }
}

@media (min-width: 1024px) {

    .alternatives-header__inner {
        padding-top: 0;
        padding-bottom: 0;
        height: 352px;
    }

    .alternatives-header__inner > h1 {
        margin-right: 120px;
    }

    .alternatives-header__inner-vs-square-rounded {
        width: 144px;
        height: 144px;
        border-radius: 32px;
    }

    .alternatives-header__inner-vs .heading_1 {
        margin: 0 24px;
    }

    .alternatives-header__inner-vs-square-rounded > img {
        height: 90px;
        width: auto;
    }
}

@media (min-width: 1280px) {

    .alternatives-header__inner {
        padding-top: 0;
        padding-bottom: 0;
        height: 400px;
    }

    .alternatives-header__inner-vs-square-rounded {
        width: 168px;
        height: 168px;
        border-radius: 40px;
    }

    .alternatives-header__inner-vs-square-rounded > img {
        height: 105px;
        width: auto;
    }
}
