.suggest-features {
    min-width: 100%;
    max-width: 100%;
    min-height: 892px;
    max-height: 892px;
    border: 0;
    border-radius: 8px;
    overflow: hidden;
}
.suggest-features__layout {
    @apply  mt-0
            mb-6
            px-0
            py-0
            h-full
            rounded-lg
            text-white
            bg-neutral-900
            320:w-[296px]
            320:mx-auto
            sm:w-[438px]
            sm:mx-auto
            md:w-[706px]
            md:mx-auto
            lg:w-[936px]
            lg:mx-auto;
}
.suggest-features__icon {
    margin: 0 auto;
    height: 192px;
    width: 100%;
    min-width: 296px;
}

iframe[title="reCAPTCHA"] {
    transform: scale(0.921051);
    margin-left: -12px;
}
