.loading div {
    @apply bg-white inline-block rounded-full w-2 h-2
        [&:not(:last-of-type)]:mr-3
        [&:nth-child(1)]:animate-[loading1_100ms_0ms_linear_alternate_infinite,loading2_500ms_0ms_linear_alternate_infinite]
        [&:nth-child(2)]:animate-[loading1_200ms_0ms_linear_alternate_infinite,loading2_500ms_200ms_linear_alternate_infinite]
        [&:nth-child(3)]:animate-[loading1_300ms_0ms_linear_alternate_infinite,loading2_500ms_400ms_linear_alternate_infinite];
}

@keyframes loading1 {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes loading2 {
    from {
        transform: scale(.8);
        opacity: 0.5;
    }
    to {
        transform: scale(1.5);
        opacity: 1;
    }
}