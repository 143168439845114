.button-group {
    @apply flex;
}

.button-group_center {
    @apply items-center;
}

.button-group_col {
    @apply w-full flex-col gap-4;
}
