.checkbox {
    @apply w-4 h-4 border border-neutral-700 rounded bg-white bg-no-repeat bg-center appearance-none
        hover:border-2 hover:border-violet-500 hover:shadow-[0_1px_3px_0_rgba(127,132,238,0.36)]
        focus:border-2 focus:border-violet-500
        active:border-2 active:border-violet-700 active:bg-violet-100
        disabled:border-neutral-400 disabled:bg-neutral-100;
}

.checkbox:checked {
    @apply w-4 h-4 border-0 bg-violet-700 bg-checkbox-checked
        hover:bg-violet-500
        focus:bg-violet-500
        active:bg-violet-900
        disabled:bg-violet-300;
}