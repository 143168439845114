.heading {
    @apply relative w-full;
}

.heading_white {
    @apply text-white;
}

.heading_red_500 {
    @apply text-red-500;
}

.heading_neutral_900 {
    @apply text-neutral-900;
}

.heading_text-center {
    @apply text-center;
}

.heading_12xl {
    @apply text-[12rem] leading-none !important;
}
