.input {
    @apply block w-full h-12 px-4 py-3.5 text-sm leading-5 border border-neutral-500 rounded text-neutral-900
        placeholder:text-neutral-500
        hover:border-violet-500
        focus:border-violet-500 focus:shadow-[0_4px_7px_0_rgba(127,132,238,0.16)]
        disabled:border-neutral-300 disabled:bg-neutral-100;
}

textarea.input {
    @apply resize-y h-auto;
}

.input_search {
    @apply text-sm py-4.5 pl-10 border-0 shadow-[0_4px_10px_0_rgba(44,46,94,0.13)];
}
.input_search + .icon {
    @apply absolute top-4 left-4;
}
.input_icon-right + .icon {
    @apply left-auto right-1;
}

.input_no-shadow {
    @apply shadow-none
        focus:shadow-none;
}

.input_no-shadow {
    @apply shadow-none
        focus:shadow-none;
}

.input_invalid {
    @apply border-red-500;
}
.input_hidden {
    @apply hidden;
}

.input_theme_pricing {
    @apply shadow-[0_4px_10px_0_rgba(44,46,94,0.13)];
}
