.picture {
    @apply w-full relative;
}
.picture img {
    @apply rounded-lg
}

.picture_order_first {
    @apply order-first md:order-last;
}

.picture_order_last {
    @apply order-last md:order-first;
}
.picture_centered img {
    @apply mx-auto;
}
.section_text-block-image_lg .picture_order_first {
    @apply order-first lg:order-last;
}
.section_text-block-image_lg .picture_order_last {
    @apply order-last lg:order-first;
}
/*
.picture_center img {
    @apply mx-auto;
} */
/* .picture_relative {
    @apply relative;
}  */