.hero {
    @apply py-16;
}
.hero_center {
    @apply text-center;
}
.hero_bg-neutral_900 {
    @apply bg-neutral-900;
}

.hero_theme_wide {
    @apply relative overflow-hidden pt-6 pb-0 bg-neutral-900
        md:pt-0
        xl:pt-6;
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .hero_theme_wide .container {
        @apply max-w-full;
    }
    .hero_theme_wide .grid {
        @apply h-[480px];
    }
    .hero_theme_wide .picture {
        @apply w-[454px] absolute -right-22;
    }
}

.hero.hero_home {
    @apply p-0 my-20 mt-10
        sm:my-20
        xl:mt-30;
}

.hero.hero_timer {
    @apply relative overflow-hidden 480:py-[80px]
        md:pb-[128px]
        lg:pb-[160px]
        xl:pt-[120px] xl:pb-[240px];
}

.hero.hero_split {
    @apply relative overflow-hidden 480:py-[80px]
        xl:py-[120px];
}

.hero_fullscreen {
    @apply !fixed top-0 right-0 bottom-0 left-0 z-[999] max-h-screen !overflow-auto;
}

.hero:is(.hero_timer, .hero_split).hero_fullscreen {
    @apply flex flex-col justify-center items-center py-0;
}

/* .hero .button {
    @apply mt-4;
} */
/* .hero img {
    @apply mx-auto;
} */