.file-uploader {
    @apply relative
        md:px-4 md:py-6 md:text-center md:bg-white md:border md:border-dashed md:border-neutral-500 md:rounded-lg;
}

.file-uploader_dragging {
    @apply md:border-violet-500;
}

.file-uploader_invalid {
    @apply border-red-700;
}