.switch-to-traqq-narrow {
    @apply flex relative overflow-hidden bg-neutral-900 pb-4
        lg:h-18
        md:h-26
        sm:h-[116px] sm:pb-0;
}

.switch-to-traqq-narrow__bg {
    @apply hidden h-full w-28 absolute top-1/2 left-0 -translate-y-1/2 bg-no-repeat bg-left
        md:block;
}

.switch-to-traqq-narrow__layout {
    @apply h-full flex flex-col items-baseline gap-x-4 relative
        lg:gap-x-0
        md:gap-x-8
        sm:flex-row sm:items-center;
}
.switch-to-traqq-narrow__layout .heading.h3 {
    @apply lg:w-full
        md:w-1/2
        sm:pr-10;
}

.switch-to-traqq-narrow__discount {
    @apply mb-3 relative order-first
        md:order-2 md:w-1/2 md:h-full
        sm:w-1/4 sm:mb-0;
}
.switch-to-traqq-narrow__discount-bg {
    @apply hidden h-full w-full absolute right-0 bg-no-repeat
        xl:bg-right
        lg:bg-left
        md:block;
}

.switch-to-traqq-narrow__text {
    @apply -ml-6 px-6 py-4 rounded-br-[2rem] bg-red-900
        lg:right-1/3
        md:absolute md:right-[38%] md:top-1/2 md:-translate-y-1/2 md:bg-transparent
        sm:rounded-2xl sm:ml-0 sm:px-4;
}
.switch-to-traqq-narrow__text .heading.h2 {
    @apply text-3xl leading-8 font-extrabold
        md:text-[2.625rem] md:lowercase;
}

.switch-to-traqq-narrow__close {
    @apply absolute top-1/4 right-5 -translate-y-1/2 h-[26px] sm:top-1/2 sm:right-8;
}