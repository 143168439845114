.grid {
    display: grid;
}

.grid_xs_1 {
    @apply grid-cols-1;
}
.grid_xs_2 {
    @apply grid-cols-2;
}
.grid_xs_3 {
    @apply grid-cols-3;
}

.grid_sm_1 {
    @apply sm:grid-cols-1;
}
.grid_sm_2 {
    @apply sm:grid-cols-2;
}

.grid_md_1 {
    @apply md:grid-cols-1;
}
.grid_md_2 {
    @apply md:grid-cols-2;
}
.grid_md_3 {
    @apply md:grid-cols-3;
}
.grid_md_4 {
    @apply md:grid-cols-4;
}
.grid_md_auto {
    @apply md:grid-cols-[auto_auto] justify-between items-baseline;
}

.grid_xl_2 {
    @apply xl:grid-cols-2;
}
.grid_xl_3 {
    @apply xl:grid-cols-3;
}
.grid_xl_4 {
    @apply xl:grid-cols-4;
}

.grid_lg_1 {
    @apply lg:grid-cols-1;
}
.grid_lg_2 {
    @apply lg:grid-cols-2;
}
.grid_lg_3 {
    @apply lg:grid-cols-3;
}
.grid_lg_4 {
    @apply lg:grid-cols-4;
}

.grid_cols_tq {
    @apply xl:grid-cols-[580px_minmax(0,_1fr)]
        lg:grid-cols-[460px_minmax(0,_1fr)]
        md:grid-cols-[340px_minmax(0,_1fr)]
}
.grid_cols_tqr {
    @apply xl:grid-cols-[minmax(0,_1fr)_580px]
        lg:grid-cols-[minmax(0,_1fr)_460px]
        md:grid-cols-[minmax(0,_1fr)_340px];
}
.grid_cols_tab-panel_use-cases {
    @apply xl:grid-cols-[minmax(0,_1fr)_580px]
        lg:grid-cols-[minmax(0,_1fr)_430px];
}
.grid_cols_demo {
    @apply xl:grid-cols-[minmax(0,_1fr)_610px]
        lg:grid-cols-[minmax(0,_1fr)_500px];
}
.grid_pricing {
    @apply gap-y-5 480:gap-y-6  xl:grid-cols-[minmax(0,_1fr)_360px_minmax(0,_1fr)];
}

.grid_items_center {
    @apply items-center;
}

.grid_items_start {
    @apply items-start;
}

.grid_g_2 {
    @apply gap-2;
}
.grid_g_4 {
    @apply gap-4;
}
.grid_g_6 {
    @apply gap-6;
}
.grid_g_8 {
    @apply gap-8;
}
.grid_g_10 {
    @apply gap-10;
}
.grid_g_22 {
    @apply gap-22;
}

.grid_gx_4 {
    @apply gap-x-4;
}
.grid_gx_6 {
    @apply gap-x-6;
}
.grid_gx_8 {
    @apply gap-x-8;
}
.grid_gx_10 {
    @apply gap-x-10;
}
.grid_gx_12 {
    @apply gap-x-12;
}
.grid_gx_14 {
    @apply gap-x-14;
}
.grid_gx_16 {
    @apply gap-x-16;
}
.grid_gx_18 {
    @apply gap-x-18;
}
.grid_gx_20 {
    @apply gap-x-20;
}

.grid_gy_4 {
    @apply gap-y-4;
}
.grid_gy_5 {
    @apply gap-y-5;
}
.grid_gy_6 {
    @apply gap-y-6;
}
.grid_gy_8 {
    @apply gap-y-8;
}
.grid_gy_10 {
    @apply gap-y-10;
}
.grid_gy_12 {
    @apply gap-y-12;
}
.grid_gy_15 {
    @apply gap-y-15;
}

.grid_pt_12 {
    @apply pt-12;
}

.grid_pb_12 {
    @apply pb-12;
}

.grid_py_12 {
    @apply py-12;
}

.grid_divide > * {
    @apply relative;
}
.grid_divide > *:not(:first-child):before {
    @apply content-[''] absolute bg-neutral-400 w-full h-[1px] left-0 top-0
        md:h-full md:w-[1px] md:left-0
        lg:h-[84%] lg:!top-1/2 lg:-translate-y-1/2;
}
.grid_divide.grid_gx_20 > *:not(:first-child):before {
    @apply md:-left-10;
}
.grid_divide.grid_gy_8 > *:not(:first-child):before {
    @apply -top-4 md:top-auto;
}

.grid_shadow {
    box-shadow: 0 6.65px 5.32px 0 rgba(103, 107, 210, 0.0283),
        0 12.52px 10.02px 0 rgba(103, 107, 210, 0.035),
        0 22.34px 17.87px 0 rgba(103, 107, 210, 0.0417),
        0 100px 80px 0 rgba(103, 107, 210, 0.07),
        0 -40px 80px 0 rgba(103, 107, 210, 0.07);
}

.grid_bordered {
    --gap: 65px;
    --line-offset: calc(var(--gap) / 2);
    --line-thickness: 1px;
    --line-color: rgba(222, 223, 255, 1);
    overflow: hidden;
    gap: var(--gap);
}
.grid_bordered > * {
    @apply relative;
}
.grid_bordered > *::before,
.grid_bordered > *::after {
    @apply content-[''] absolute z-0;
    background-color: var(--line-color);
}


.grid_bordered > *::after {
    inline-size: 200%;
    block-size: var(--line-thickness);
    inset-inline-start: 0;
    inset-block-start: calc(var(--line-offset) * -1);
}

.grid_bordered > *::before {
    inline-size: var(--line-thickness);
    block-size: 200%;
    inset-block-start: 0;
    inset-inline-start: calc(var(--line-offset) * -1);
}