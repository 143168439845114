@import 'alternatives/alternatives.css';

.mce-content-body [contentEditable=false][data-mce-selected] {
    cursor: default;
}

.editor-post .paragraph {
    margin: 16px 0;
    color: var(--color-neutral-700);
}

.editor-post .paragraph img {
    margin-left: 0 !important;
}

.heading_1 {
    font-size: 1.75rem;
    line-height: 2.5rem;
    font-weight: 800;
}

@media (min-width: 480px) {
}

@media (min-width: 768px) {

}

@media (min-width: 1024px) {
    .heading_1 {
        font-size: 2.625rem;
        line-height: 3.5rem;
    }
}

@media (min-width: 1280px) {

}
