.tab-button {
    @apply relative inline-flex items-center gap-2 font-semibold whitespace-nowrap text-neutral-900;
}

.tab-button_primary {
    @apply px-6 py-3;
}

.tab-button_primary.active {
    @apply rounded-lg bg-violet-100;
}

.tab-button_secondary {
    @apply p-6 text-[15px] leading-5
    hover:bg-violet-100 hover:text-primary-violet
    focus:bg-violet-100 focus:text-primary-violet;
}

.tab-button_secondary.active {
    @apply text-violet-700
    after:content-[''] after:w-full after:absolute after:left-0 after:bottom-0 after:border-b-[3px] after:border-violet-500;
}

.tab-button > .progress {
    @apply absolute bottom-1 left-2;
}

.tab-button .progress-bar {
    @apply max-w-[calc(100%-1rem)];
}

.tab-button_dark.active {
    @apply text-white bg-neutral-900;
}

.tab-button_rounded {
    @apply rounded-full py-3 px-6 bg-white border border-neutral-400;
}

.tab-button_rounded.active {
    @apply bg-neutral-300 border-neutral-300;
}

.tab-button_violet {
    @apply bg-[#F7F8FF] border-[#F7F8FF];
}

.tab-button_violet.active {
    @apply bg-violet-700 text-white;
}

.tab-button_lg {
    @apply w-[160px] py-3 text-[14px] font-[600] leading-5 items-center justify-center
        lg:text-[18px] lg:leading-6;
}

.tabs_fluid .tab-button_rounded {
    @apply max-sm:px-3;
}