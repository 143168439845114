.pricing__price {
    @apply inline-flex mb-2 font-medium text-neutral-900 text-[6.25rem] leading-[6rem];
}

.pricing__price_text_custom {
    @apply text-[1.75rem] leading-[2rem] uppercase font-bold flex justify-center items-center h-[6rem];
    white-space: pre-line;
}

.pricing__price_violet_700 {
    @apply text-violet-700;
}

.pricing__price-currency {
    @apply text-[2.75rem] leading-[4rem] flex flex-col justify-start;
}

.pricing__price-currency:after {
    @apply text-15p font-bold leading-15p -mt-[0.25rem];
    content: 'USD';
}
