.pagination__item {
    @apply relative text-center whitespace-nowrap
        [&:first-child]:hidden [&:first-child]:md:block [&:first-child]:text-violet-700 [&:first-child]:mr-auto
        [&:last-child]:hidden [&:last-child]:md:block [&:last-child]:text-violet-700 [&:last-child]:ml-auto;
}
.pagination__item:not(:first-child):not(:last-child) {
    @apply after:hover:content-[''] after:hover:w-full after:hover:h-[2px] after:hover:absolute after:hover:left-0 after:hover:bg-violet-500;
}

.pagination__item.active {
    @apply after:content-[''] after:w-full after:h-[2px] after:absolute after:left-0 after:bg-violet-500;
}

.pagination__item.disabled {
    @apply pointer-events-none text-violet-300;
}