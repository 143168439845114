.card {
    @apply relative flex flex-col rounded-2xl;
}

.card .heading,
.card .paragraph {
    @apply mb-2
        [&:last-child]:mb-0;
}

.card_features .heading {
    @apply mb-4;
}

.card_center {
    @apply items-center text-center;
}

.card_no-spacing .card__body {
    @apply px-0;
}

.card_no-spacing-y .card__body {
    @apply py-0;
}

.card_violet {
    @apply bg-violet-100 border-2 border-transparent transition-colors duration-300
        hover:border-violet-300
        active:border-violet-500;
}
.card_red {
    @apply bg-red-100 border-2 border-transparent transition-colors duration-300
        hover:border-red-300
        active:border-red-500;
}
.card_yellow {
    @apply bg-yellow-100 border-2 border-transparent transition-colors duration-300
        hover:border-yellow-300
        active:border-yellow-500;
}
.card_green {
    @apply bg-green-100 border-2 border-transparent transition-colors duration-300
        hover:border-green-300
        active:border-green-500;
}
.card_white {
    @apply bg-white;
}

.card_grey {
    @apply bg-neutral-100;
}

.card_features .card__body {
    @apply px-4 pb-8 pt-6
        md:px-4 md:pb-8
        lg:px-10 lg:pb-8;
}
.card_use-cases {
    @apply mb-12
        xl:my-16
        md:mt-12;
}
.card_use-cases .card__body {
    @apply pt-4
        xl:px-24
        lg:px-12 lg:pt-0 lg:pb-10
        md:p-4 md:pb-8;
}

.card_text-block .card__body {
    @apply p-0 flex flex-col gap-4;
}
.card_text-block .heading,
.card_text-block .paragraph {
    @apply my-0;
}
.card_simple {
    @apply border-2 border-neutral-300 gap-y-6 p-6 rounded-lg
        480:p-8
        1024:p-10;
}
.card_simple_2 {
    @apply border-2 border-neutral-300 gap-y-6 p-6 rounded-lg
    480:p-8
    1024:px-10;
}
.card_simple.card_simple_custom .card__body {
    @apply gap-0;
}
.card_simple.card_simple_custom .card__body .heading + p {
    @apply mt-2;
}
.card_simple.card_simple_custom .card__body .list {
    @apply mt-6;
}
.card_plain {
    @apply pt-6 border-t-2 border-neutral-300 rounded-none
        768:pt-8;
}
.card_no-border, .card_simple.card_no-border {
    border: none;
}
.card .heading[data-order] {
    @apply flex;
}
.card .heading[data-order]:before {
    @apply content-[attr(data-order)'.'] mx-1 lg:mx-[6px];
}

.card_shadow {
    @apply bg-white;
    box-shadow: 0 3.43px 2.75px 0 rgba(35, 37, 87, 0.0093),
        0 8.69px 6.95px 0 rgba(35, 37, 87, 0.0133),
        0 17.72px 14.18px 0 rgba(35, 37, 87, 0.0167),
        0 36.5px 29.2px 0 rgba(35, 37, 87, 0.0207);
}

.card_hover .card__body .heading, .card_hover .card__heading {
    @apply transition-colors duration-300;
}

.card_hover:hover .card__body .heading {
    @apply text-violet-500;
}

.card_hover {
    @apply overflow-hidden border-2 border-transparent transition-colors duration-300;
}

.card_hover_red:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
        linear-gradient(0deg, rgba(255, 96, 74, 0.05), rgba(255, 96, 74, 0.05));

    @apply border-red-300;
}
.card_hover_red:hover .card__heading {
    @apply text-red-900;
}

.card_hover_green:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
    linear-gradient(0deg, rgba(32, 194, 136, 0.05), rgba(32, 194, 136, 0.05));

    @apply border-green-300;
}
.card_hover_green:hover .card__heading {
    @apply text-green-900;
}

.card_hover_yellow:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
    linear-gradient(0deg, rgba(255, 205, 61, 0.05), rgba(255, 205, 61, 0.05));

    @apply border-yellow-300;
}
.card_hover_yellow:hover .card__heading {
    @apply text-yellow-900;
}

.card_hover_blue:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
    linear-gradient(0deg, rgba(62, 141, 246, 0.05), rgba(62, 141, 246, 0.05));

    @apply border-blue-100;
}
.card_hover_blue:hover .card__heading {
    @apply text-blue-700;
}

.card_hover_violet:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
    linear-gradient(0deg, rgba(103, 107, 210, 0.05), rgba(103, 107, 210, 0.05));

    @apply border-violet-300;
}
.card_hover_violet:hover .card__heading {
    @apply text-violet-700;
}

.card_hover_gray:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
    linear-gradient(0deg, rgba(61, 59, 79, 0.05), rgba(61, 59, 79, 0.05));

    @apply border-neutral-450;
}
.card_hover_gray:hover .card__heading {
    @apply text-neutral-700;
}