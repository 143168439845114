.alert {
    @apply mb-6 p-4 rounded text-xs text-neutral-900;
}

.alert_info,
.alert_violet {
    @apply border border-violet-700 bg-violet-100;
}

.alert_success,
.alert_green {
    @apply border border-green-700 bg-green-100;
}

.alert_warning,
.alert_yellow {
    @apply border border-yellow-700 bg-yellow-100;
}

.alert_danger,
.alert_red {
    @apply border border-red-700 bg-red-100;
}