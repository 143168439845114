.switch-to-traqq-wide__text {
    @apply relative text-center -order-1 md:order-1;
}

.switch-to-traqq-wide__text img {
    @apply block mx-auto md:hidden;
}

.switch-to-traqq-wide__text span {
    @apply absolute font-black uppercase -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-neutral-100
        lg:static
}
