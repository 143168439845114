.breadcrumbs {
    @apply flex gap-x-1;
}

.breadcrumbs li {
    @apply flex items-center gap-x-1 text-xs leading-5;
}

.breadcrumbs li a {
    @apply text-primary-violet
        hover:text-violet-500
        active:text-violet-900;
}