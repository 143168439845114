.demo {
    @apply justify-self-center bg-white rounded-2xl p-8 my-8
        xl:p-12 xl:mt-8 xl:mb-18
        lg:p-10;
}
.demo > .grid {
    @apply gap-x-14
        xl:gap-x-22;
}

.demo__card {
    @apply order-last gap-x-6 mt-8
        md:grid-cols-[144px_210px_1fr]
        lg:order-first lg:grid-cols-1 lg:mt-0;
}
.demo__card-image {
    @apply relative;
}
.demo__card-body {
    @apply text-center
        md:text-left md:py-2
        lg:text-center lg:py-0 lg:px-8;
}
.demo__card-body > .h4 {
    @apply mb-4;
}

.demo__rate {
    @apply flex flex-col items-center gap-y-4 mt-6 pt-6 border-t border-neutral-300
        md:mt-0 md:py-2 md:border-l md:border-t-0 md:pl-6
        lg:mt-6 lg:pt-6 lg:pb-0 lg:pl-0 lg:border-t lg:border-l-0
}

.demo__rate img {
    @apply lg:h-[78px]
}

.demo__form > .info {
    @apply mb-8;
}
