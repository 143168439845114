.tab-content {
    @apply flex justify-center;
}

.tab-content_use-cases .container_wide {
    @apply relative overflow-hidden pt-8
        xl:pr-20
        lg:pr-0 lg:pt-0;
}
.tab-content_use-cases .picture {
    @apply mx-auto
        md:max-w-[550px]
        lg:max-w-full;
}
.tab-content_use-cases .grid {
    @apply xl:gap-x-20;
}

@media only screen and (max-width: 1279px) and (min-width: 1024px) {
    .tab-content_use-cases .grid {
        @apply h-[540px];
    }

    .tab-content_use-cases .picture {
        @apply w-[510px] absolute -right-28;
    }
}