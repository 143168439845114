.pagination {
    @apply flex gap-x-2 justify-center;
}

.pagination .link {
    @apply block text-sm leading-6 px-2.5;
}

.pagination_wide {
    @apply mb-16;
}
