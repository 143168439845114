.file-uploader-file {
    @apply flex items-center p-4 mt-5 rounded-lg bg-neutral-100;
}

.file-uploader-file-image {
    @apply min-w-[40px] h-[40px] mr-4;
}

.file-uploader-file-close {
    @apply ml-auto;
}