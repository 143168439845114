.icon {
    @apply inline-block;
}

.icon_block {
    @apply block;
}

.icon_bg-white {
    @apply bg-white;
}

.icon_bottom-overlap {
    @apply absolute left-1/2 -bottom-6 -translate-x-1/2 p-3 rounded-full;
}