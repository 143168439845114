.alternatives {
    @apply mb-20;
}

.alternatives__post {
    @apply w-full flex-shrink-0
        xl:w-[744px]
        lg:w-[598px];
}

/* .alternatives__post > header ~ nav {
    @apply hidden;
} */

.alternatives__content {
    @apply grid grid-cols-1 mt-15
        xl:gap-x-18
        lg:gap-x-14 lg:grid-cols-[minmax(0,_1fr)_312px];
}

.alternatives__aside {
    @apply order-first
        lg:order-last;
}