.switch-to-traqq-wide__bg {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTA1IiBoZWlnaHQ9IjU2MCIgdmlld0JveD0iMCAwIDkwNSA1NjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF82OTU5Xzc4OTExKSI+CjxwYXRoIGQ9Ik0yOS4xMTI4IDQzMS4yMzZDLTIuNTczNjEgNDA1LjEwNCAtNyAzNjUgMTMuNDc1IDMyNS4wNjRMMjkzLjYzNiAtMjAyLjQwNkMzMTQuNiAtMjMzLjgwNiAzNTQuNjU3IC0yNDYuMjc5IDM4OS43MzkgLTIzMi4zMzFMODU0LjUzMSAtNzYuMTU4NEM5MTEuODA1IC01My4zODYyIDkyMi45MTEgMjIuODc0OCA4NzQuNTExIDYxLjAzOTNMMjYzLjUyMiA1NDIuODE3QzIzMy45NTIgNTY2LjEzNCAxOTIuMTI3IDU2NS42NzUgMTYzLjA3NiA1NDEuNzE2TDI5LjExMjggNDMxLjIzNloiIGZpbGw9IiNGOTU4NDIiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF82OTU5Xzc4OTExIj4KPHJlY3Qgd2lkdGg9IjkwNSIgaGVpZ2h0PSI1NjAiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==');
    @apply absolute hidden h-full w-[53%] bg-no-repeat
        xl:-right-[60px]
        lg:-right-[20px] lg:w-1/2
        md:block md:right-0;
}

.switch-to-traqq-wide__bg_narrow {
    @apply h-[103%] bg-left-bottom -top-0 [background-position-x:6px];
}

.switch-to-traqq-wide__bg_wide {
    @apply -z-10
        xl:-top-8
        lg:-top-3
        md:-top-22;
}
