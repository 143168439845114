:root{
    --color-neutral-100: #F9F9FD;
    --color-neutral-450: #D0D2DC;
    --color-neutral-700: #585668;
    --color-neutral-900: #3D3B4F;
    --color-yellow-900: #FFC621;
    --color-yellow-100: #FFF8DD;
    --color-green-100: #EBFAF4;
    --color-red-100: #FFEBE8;
    --color-red-500: #FF7664;
    --color-violet-500: #7F84EE;
    --color-violet-700: #676BD2;
    --size-14: 0.875rem;
    --sm: 480px;
    --md: 768px;
    --lg: 1024px;
    --xl: 1280px;
}
