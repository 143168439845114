.updates__post {
    @apply w-full flex-shrink-0
    xl:w-[744px]
    lg:w-[598px];
}

.updates__list {
    @apply flex flex-col gap-y-4 not-last:mb-8
}

.updates__aside {
    @apply order-first
    lg:order-last;
}

.updates__item {
    @apply 480:pl-8 relative
}

.updates__title {
    @apply h2 mb-6
}

.updates__subtitle {
    @apply h3 mb-4
}

.updates__title::before {
    display: block;
    content: '';
    position: absolute;
    left: -8.5px;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    @apply 480:border 480:bg-white border-solid border-neutral-500
    top-3 1024:top-4 768:top-3.5
}

.updates__list * {
    @apply pl-7 desktop-body-2 text-neutral-700
}

.updates-list_news > * {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.8' d='M7.61539 1.66026L3.10769 3.53846C2.69744 3.7094 2.69744 4.2906 3.10769 4.46154L7.61538 6.33974C7.86154 6.44231 8.13846 6.44231 8.38461 6.33974L12.8923 4.46154C13.3026 4.2906 13.3026 3.7094 12.8923 3.53846L8.38462 1.66026C8.13846 1.55769 7.86154 1.55769 7.61539 1.66026Z' fill='%230EA972'/%3E%3Cpath opacity='0.3' d='M14.0577 5.78846L9.36538 7.74359C8.99274 7.89886 8.75 8.26297 8.75 8.66667V13.691C8.75 14.0627 9.14116 14.3044 9.47361 14.1382L14.1972 11.7764C14.536 11.607 14.75 11.2607 14.75 10.882V6.25C14.75 5.89321 14.387 5.65123 14.0577 5.78846Z' fill='%230EA972'/%3E%3Cpath opacity='0.3' d='M1.94231 5.78846L6.63462 7.74359C7.00726 7.89886 7.25 8.26297 7.25 8.66667V13.691C7.25 14.0627 6.85884 14.3044 6.52639 14.1382L1.80279 11.7764C1.464 11.607 1.25 11.2607 1.25 10.882V6.25C1.25 5.89321 1.61296 5.65123 1.94231 5.78846Z' fill='%230EA972'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-y: 4px;
}

.updates-list_fixes > * {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.8' d='M7.61539 1.66123L3.10769 3.53944C2.69744 3.71038 2.69744 4.29157 3.10769 4.46251L7.61538 6.34072C7.86154 6.44328 8.13846 6.44328 8.38461 6.34072L12.8923 4.46252C13.3026 4.29157 13.3026 3.71038 12.8923 3.53944L8.38462 1.66123C8.13846 1.55867 7.86154 1.55867 7.61539 1.66123Z' fill='%23585668'/%3E%3Cpath opacity='0.22' d='M14.0577 5.78944L9.36538 7.74457C8.99274 7.89984 8.75 8.26394 8.75 8.66764V13.692C8.75 14.0637 9.14116 14.3054 9.47361 14.1392L14.1972 11.7774C14.536 11.608 14.75 11.2617 14.75 10.8829V6.25098C14.75 5.89419 14.387 5.65221 14.0577 5.78944Z' fill='%23585668'/%3E%3Cpath opacity='0.22' d='M1.94231 5.78944L6.63462 7.74457C7.00726 7.89984 7.25 8.26394 7.25 8.66764V13.692C7.25 14.0637 6.85884 14.3054 6.52639 14.1392L1.80279 11.7774C1.464 11.608 1.25 11.2617 1.25 10.8829V6.25098C1.25 5.89419 1.61296 5.65221 1.94231 5.78944Z' fill='%23585668'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-y: 4px;
}
