.info {
    @apply flex flex-col items-start gap-6 rounded-2xl;
}

.info_row {
    @apply flex-row;
}

.info_center {
    @apply items-center justify-center text-center;
}
.info_right {
    @apply items-center justify-end;
}
.info_accordion {
    @apply items-center gap-[1rem];
}
@media only screen and (max-width: 767px) {
    .info_md_center {
        @apply items-center justify-center text-center;
    }
}
@media only screen and (max-width: 1023px) {
    .info_lg_center {
        @apply items-center justify-center text-center;
    }
}

.info_gap_0 {
    @apply gap-0;
}
.info_gap_1 {
    @apply gap-1;
}
.info_gap_2 {
    @apply gap-2;
}
.info_gap_4 {
    @apply gap-4;
}
.info_gap_8 {
    @apply gap-8;
}

.info_theme_copyright {
    @apply items-baseline justify-between;
}

.info .button:not(.button_link):not(.button_no-spacing) {
    @apply mt-4;
}
.info + .list_bordered {
    @apply mt-10;
}

.info__button-wrapper {
    @apply relative w-full flex flex-col gap-y-6 gap-x-4 items-center
        768:flex-row 768:flex-nowrap
        1024:mt-4;
}

.info__button-wrapper .button_lg {
    @apply 768:text-base 768:leading-5
        1024:text-[18px] 1024:leading-6;
}

.info__button-wrapper .button_video {
    @apply 768:absolute 768:-right-[36px]
        1024:relative 1024:right-auto;
}