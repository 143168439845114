.card__body {
    @apply p-4
        /* [&>*:not(:last-child)]:mb-2; */
        /* md:p-2 md:pt-8 lg:p-8; */
}

/* .card__body .paragraph {
    @apply mt-4;
} */

/* .card__body_theme_about-us {
    @apply px-2 pb-0;
} */

/* .card__body_pb_0 {
    @apply pb-0;
} */