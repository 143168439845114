.list__item {
    @apply flex gap-2;
}

.list__item_gap_4 {
    @apply gap-4;
}

.list__item_action {
    @apply transition-colors duration-300 text-primary-violet
        hover:text-violet-500
        active:text-violet-900;
}