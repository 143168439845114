.not-found-wrap {
    @apply bg-violet-900;
}

.not-found {
    @apply flex flex-col justify-center items-center gap-x-18 gap-y-6 mx-auto max-w-[464px]
        xl:max-w-[936px] xl:gap-x-30
        lg:flex-row lg:max-w-[748px] lg:min-h-[400px] lg:gap-x-20;
}

.not-found .info {
    @apply px-11 text-center lg:text-left
        md:px-0;
}

.not-found__image {
    @apply w-full mx-auto -order-1 max-w-[288px]
        xl:max-w-[360px]
        lg:order-none;
}