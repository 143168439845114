@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "base.css";
@import "components.css";
@import "typography.css";

@import '../screens/Updates/Updates.css';

@import '../components/UI/Button/Button.css';

@import '../components/Nav/NavLink.css';

@import '../components/UI/Container/Container.css';

@import '../components/ScrollNavBlock/ScrollNavBlock.css';