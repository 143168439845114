.accordion-wrap {
    @apply flex flex-col items-center;
}

.accordion-wrap .accordion {
    @apply w-full mb-6;
}

.accordion-wrap .button {
    @apply flex items-end;
}