.section {
    @apply mb-16 overflow-hidden;
}

.section_no-spacing {
    @apply p-0 m-0;
}

.section_pt_12 {
    @apply pt-12;
}

.section_py_18 {
    @apply py-18;
}

.section_py_20 {
    @apply py-20;
}

.section_bg-neutral_100 {
    @apply bg-neutral-100;
}
.section_bg-neutral_900 {
    @apply bg-neutral-900;
}

.section_use-cases {
    @apply flex justify-center mb-0;
}
.section_use-cases .container_wide {
    @apply py-14;
}

.section_narrow > .container {
    @apply xl:max-w-[936px] xl:px-0;
}
.section_narrow > * > .info {
    @apply mb-14;
}

.section_pricing {
    @apply mb-0 py-[2rem] 480:py-[4rem];
}

.section_pricing .section__headline,
.section_pricing-accordion .section__headline {
    /*@apply md:max-w-[552px] md:mx-auto;*/
    @apply mb-[3.5rem];
}

@media only screen and (max-width: 479px) {
    .section_pricing > .container,
    .section_pricing-accordion > .container {
        @apply max-w-[100%];
    }
}

.section_pricing + .section_pricing-accordion {
    @apply relative mb-0;
}

.section_pricing + .section_pricing-accordion:before {
    @apply content-[''] absolute w-full top-0 left-0 bottom-0;
    background: linear-gradient(180deg, rgba(88, 86, 104, 0.50) 0%, rgba(88, 86, 104, 0.00) 100%);
}

.section_pricing-faq {
    @apply mb-0 py-18;
}

.section_switch-to-traqq {
    @apply py-8
        lg:py-18;
}
.section_mb-12 {
    @apply mb-12;
}

.section_text-block {
    @apply mb-0 overflow-visible py-12 1024:py-16;
}
.section_text-block-image .grid .picture {
    @apply hidden 768:block;
}
.section_text-block-image.section_text-block-image_lg .grid .picture {
    @apply hidden lg:block;
}
.section__intro-image {
    @apply 768:hidden;
}
.section_text-block-image_lg .section__intro-image {
    @apply block 1024:hidden;
}
.section_text-block .section__headline {
    @apply flex flex-row flex-wrap mx-auto mb-8
        768:mx-12
        1024:mx-16 1024:mb-12
        1280:mx-24
        last:mb-0;
}
.section_text-block .section__headline .heading {
    @apply 1024:mx-24 1280:mx-auto;
}
.section_text-block .section__headline .paragraph {
    @apply mt-4;
}
.section_text-block .section__headline .heading+.paragraph {
    @apply mt-6;
}
.section_text-block .paragraph {
    @apply text-base;
}
.section_text-block .section__headline .button {
    @apply mt-0;
}
.section_feature-links {
    @apply -mt-4;
}
.section_feature-links .section__headline {
    @apply mb-14;
}
.section_home {
    @apply mt-12 overflow-visible
        md:mt-20
        xl:mt-30;
}