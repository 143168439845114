.select {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjA1MTU1IDVMOCAxMC45MTA0TDEzLjk0ODUgNUwxNSA2LjA0NDgxTDggMTNMMSA2LjA0NDgyTDIuMDUxNTUgNVoiIGZpbGw9IiM1ODU2NjgiLz4KPC9zdmc+Cg==);
    background-position: calc(100% - 16px) 16px;

    @apply block w-full h-12 px-4 text-sm leading-5 bg-white border border-neutral-500 rounded text-neutral-900 appearance-none bg-no-repeat
        placeholder:text-neutral-500
        hover:border-violet-500
        focus:border-violet-500 focus:shadow-[0_4px_7px_0_rgba(127,132,238,0.16)]
        disabled:border-neutral-300 disabled:bg-neutral-100;
}

.select_invalid {
    @apply border-red-500;
}