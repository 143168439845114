.alternatives-about {
    border-left: 2px solid;
    border-color: var(--color-violet-500);
    padding: 20px 16px;
    margin: 20px 0;
}

.alternatives-about .button {
    margin-top: 24px;
}

.alternatives-about_border-red-500{
    border-color: var(--color-red-500);
}

.alternatives-about__logo-container {
    display: flex;
    background-color: var(--color-neutral-100);
    padding: 12px 52px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.alternatives-about__logo-container img {
    height: 32px;
}

.alternatives-about > h3 {
    margin-top: 24px;
}

.alternatives-about > ul > li {
    display: flex;
    /*align-items: center;*/
}

.alternatives-about > ul > li::before {
    content: '';
    display: inline-block;
    width: 6px;
    min-width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--color-neutral-700);
    vertical-align: middle;
    margin: 0 9px;
    margin-top: 7px;
}

.alternatives-about > ul > li:last-child{
    margin-bottom: 0;
}

@media (min-width: 375px) {
    .alternatives-about__logo-container {
        padding: 12px 62px;
    }

    .alternatives-about__logo-container img {
        height: 40px;
    }
}

@media (min-width: 480px) {
    .alternatives-about {
        padding: 24px;
        margin: 32px 0;
    }

    .alternatives-about__logo-container {
        padding: 16px 66px;
    }

    .alternatives-about > ul > li::before {
        margin-top: 9px;
    }
}

@media (min-width: 768px) {
    .alternatives-about {
        padding: 32px;
        margin: 48px 0;
    }

    .alternatives-about > h3 {
        margin-top: 32px;
    }

    .alternatives-about .button {
        margin-top: 32px;
    }
}

@media (min-width: 1024px) {
    .alternatives-about > ul > li::before {
        margin-top: 11px;
    }
}

@media (min-width: 1280px) {

}
