.pricing {
    /* @apply ; */
}

.pricing > .pricing-toggle {
    @apply mb-8 768:mb-[3.5rem];
}

.pricing > .info {
    @apply mt-8;
}
