.alternatives-disclaimer {
    margin: 24px 0;
    padding: 24px;
    background-color: var(--color-yellow-100);
    line-height: 20px;
    font-size: var(--size-14);
    color: var(--color-neutral-900);
    border-left: 2px solid var(--color-yellow-900);
    border-radius: 8px;
}
