.scroll-nav-block {
    @apply flex
        768:gap-x-[40px]
        1024:gap-x-[48px]
        1280:gap-x-[96px];
}
.scroll-nav-block-content__item {
    @apply grid gap-y-4;
}
.scroll-nav-block-content__item + .scroll-nav-block-content__item {
    @apply pt-8
        1280:pt-12;
}
.scroll-nav-block-content__item * {
    @apply [&:not(.heading)]:text-neutral-700;
}
.scroll-nav-block__aside {
    @apply hidden border-r-[1px] border-r-neutral-400
        768:block;
}
.scroll-nav-block__nav {
    @apply w-[202px]
        1024:w-[288px];
}
.scroll-nav {
    @apply sticky top-4;
}
.scroll-nav a {
    @apply relative mobile-body-2 py-2 pr-8 text-neutral-700 transition-colors duration-300
        768:desktop-body-3
        hover:text-violet-500
        active:text-violet-900;
}
.scroll-nav a.active:before {
    @apply content-[''] absolute w-[2px] top-0 right-0 h-full bg-neutral-900;
}
