.alternatives-feature-comparison {
    width: auto;
    margin: 24px -17.5px;
}

.alternatives-feature-comparison tr > :is(th, td) {
    border: 1px solid var(--color-neutral-450);
    width: 33.33%;
    padding: 16px 12px;
    color: var(--color-neutral-900);
    font-size: 14px;
}

.alternatives-feature-comparison tr > :is(th:is(:first-child, :last-child), td:is(:first-child, :last-child)) {
    border-left: none;
}

.alternatives-feature-comparison td:first-child {
    font-weight: 600;
    color: var(--color-neutral-700);
}

.alternatives-feature-comparison td:not(:first-child) {
    word-break: break-word;
    background-color: var(--color-neutral-100);
}

.alternatives-feature-comparison > thead img {
    height: 18px;
    margin: 0 auto;
}

.alternatives-feature-comparison__icon-with-text,
.alternatives-feature-comparison__icon-platform-with-text {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.alternatives-feature-comparison__icon-with-text img,
.alternatives-feature-comparison__icon-platform-with-text img {
    height: 20px;
    width: 20px;
}

.alternatives-feature-comparison__icon-with-text:not(:last-child){
    margin-bottom: 16px;
}

.alternatives-feature-comparison__icon-platform-with-text {
    flex-direction: column;
    margin-bottom: 16px;
}

@media (min-width: 375px) {
    .alternatives-feature-comparison > thead img {
        height: 22px;
    }

    .alternatives-feature-comparison thead > tr > th {
        padding-top: 26px;
        padding-bottom: 26px;
    }

    .alternatives-feature-comparison td {
        word-break: normal;
    }
}

@media (min-width: 480px) {
    .alternatives-feature-comparison tr > :is(th, td) {
        padding-left: 16px;
        padding-right: 16px;
    }

    .alternatives-feature-comparison > thead img {
        height: 28px;
    }

    .alternatives-feature-comparison__icon-with-text, .alternatives-feature-comparison__icon-platform-with-text {
        flex-wrap: nowrap;
        align-items: center;
    }

    .alternatives-feature-comparison {
        width: 100%;
        margin: 32px 0;
    }

    .alternatives-feature-comparison__icon-platform-with-text {
        flex-direction: row;
    }

    .alternatives-feature-comparison tr > :is(th:is(:first-child, :last-child), td:is(:first-child, :last-child)) {
        border-left: 1px solid var(--color-neutral-450);
    }
}

@media (min-width: 768px) {
    .alternatives-feature-comparison tr > :is(th, td) {
        padding-top: 26px;
        padding-bottom: 26px;
    }

    .alternatives-feature-comparison > thead img {
        height: 40px;
    }

    .alternatives-feature-comparison {
        margin: 48px 0;
    }
}

@media (min-width: 1024px) {

}

@media (min-width: 1280px) {
    .alternatives-feature-comparison > thead img {
        height: 44px;
    }
}
