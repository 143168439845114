.nav {
    @apply flex [&:not(:only-of-type)]:mb-8;
}

.nav_row {
    @apply flex-row;
}

.nav_col {
    @apply flex-col;
}