.link_underline {
    @apply underline underline-offset-2;
}

.link_semibold {
    @apply font-semibold;
}

.link_sm {
    @apply text-sm leading-4
        lg:leading-5;
}
.link_lg {
    @apply text-base
        lg:text-lg;
}
.link_xs {
    @apply text-xs leading-5;
}

.link_stretched:after {
    @apply content-[""] absolute top-0 right-0 bottom-0 left-0 z-10;
}

.link_violet {
    @apply transition-colors duration-300 text-primary-violet
        hover:text-violet-500
        focus:text-violet-500
        active:text-violet-900;
}

.link_white {
    @apply text-white;
}

.heading .link {
    @apply transition-colors duration-300
        hover:text-violet-500
        focus:text-violet-500
        active:text-violet-900;
}

.link_violet:has(.link__icon){
    @apply hover:text-violet-700;
}

.link__icon {
    @apply inline-block transition transform ml-2;
}

.link:hover .link__icon {
    @apply translate-x-1;
}