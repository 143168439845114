@layer components {
    .desktop-heading-1 {
        @apply text-48p font-[700] leading-64p -tracking-[1p]
    }

    .desktop-heading-2 {
        @apply text-34p font-[700] leading-48p
    }

    .desktop-heading-3 {
        @apply text-20p font-[700] leading-32p
    }

    .desktop-heading-4 {
        @apply text-16p font-[600] leading-20p
    }

    .desktop-body-2 {
        @apply text-16p font-[400] leading-24p
    }

    .desktop-body-3 {
        @apply text-14p font-[400] leading-20p
    }

    .desktop-body-4 {
        @apply text-12p font-[400] leading-20p
    }


    .desktop-subtitle {
        @apply text-14p font-[600] leading-20p
    }


    .mobile-heading-1 {
        @apply text-28p font-[700] leading-40p
    }

    .mobile-heading-2 {
        @apply text-24p font-[700] leading-36p
    }

    .mobile-heading-3 {
        @apply text-18p font-[700] leading-28p
    }

    .mobile-body-2 {
        @apply text-14p font-[400] leading-20p
    }

    .mobile-body-3 {
        @apply text-14p font-[400] leading-16p
    }


    .button-large {
        @apply text-18p font-[600] leading-24p
    }

    .button-medium {
        @apply text-16p font-[600] leading-20p
    }


    .h1 {
        @apply mobile-heading-1 1024:desktop-heading-1
    }

    .h2 {
        @apply mobile-heading-1 1024:desktop-heading-2
    }

    .h3 {
        @apply mobile-heading-3 1024:desktop-heading-3
    }

    .h4 {
        @apply desktop-heading-4;
    }

    h1 {
        @apply h1
    }

    h2 {
        @apply h2
    }

    h3 {
        @apply h3
    }

    h4 {
        @apply h4
    }

    /*h1 {*/
    /*    @apply text-[1.75rem] leading-10 font-extrabold*/
    /*        lg:text-[2.625rem] lg:leading-14;*/
    /*}*/
    /*h2 {*/
    /*    @apply text-[1.5rem] leading-9 font-extrabold*/
    /*        lg:text-[2.125rem] lg:leading-12;*/
    /*}*/
    /*h3 {*/
    /*    @apply text-[1.125rem] leading-7 font-bold*/
    /*        lg:text-[1.25rem] lg:leading-8;*/
    /*}*/
    /*h4 {*/
    /*    @apply text-[1rem] leading-5 font-semibold;*/
    /*}*/

    :is(.hero.hero_home) .h1 {
        @apply  text-40p font-[700] leading-56p -tracking-[1px]
            lg:desktop-heading-1;
    }

    :is(.section.section_home) .h2 {
        @apply  mobile-heading-1
        lg:text-44p lg:leading-64p lg:-tracking-[1px];
    }

    :is(.section.section_home, .cta-banner) .h3 {
        @apply  mobile-heading-1
            lg:text-40p lg:leading-56p lg:-tracking-[1px];
    }

    :is(.section.section_home, .cta-banner) .h5 {
        @apply  desktop-heading-3 font-[600]
        lg:text-24p lg:leading-40p lg:tracking-normal;
    }

    :is(.section.section_home) .h6 {
        @apply desktop-heading-3 font-[600] tracking-normal;
    }

    :is(.section.section_home, .hero_home) .h7 {
        @apply desktop-body-2 font-[600] tracking-normal
        lg:desktop-heading-3 lg:font-[600] lg:tracking-normal;
    }
}
