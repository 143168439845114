.badge {
    @apply px-2 py-1 rounded-lg font-semibold whitespace-nowrap text-xs leading-5;
}

.badge_bg-yellow {
    @apply bg-yellow-900 text-white;
}

.badge_position_top {
    @apply absolute -top-5;
}

.badge_type_discount {
    @apply bg-red-100 text-red-900 ml-2;
    @apply transition-colors duration-300;
    font-size: 0.875rem;
    pointer-events: none;
}

.badge_type_discount.badge_active {
    @apply bg-violet-900 text-violet-100;
}
