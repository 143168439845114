.button {
    @apply inline-block border rounded-8p border-transparent px-6 py-2.5 text-sm text-white text-center font-semibold transition-colors cursor-pointer duration-300
    1280:px-8 1280:py-3.5 1280:text-base 1280:leading-5;
}

.button_primary {
    @apply bg-violet-700
    hover:bg-violet-500
    focus:bg-violet-500
    active:bg-violet-900
    disabled:bg-violet-300;
}

.button_secondary {
    @apply bg-neutral-900
    hover:bg-neutral-700
    focus:bg-neutral-700
    active:bg-neutral-900
    disabled:bg-neutral-300;
}

.button_light {
    @apply bg-white
    text-neutral-900
    hover:bg-violet-600 hover:text-white
    focus:bg-violet-600 focus:text-white
    active:bg-violet-800 active:text-white;
}

.button_tertiary {
    @apply bg-transparent text-primary-violet
    hover:text-violet-500
    focus:text-violet-500
    active:text-violet-900;
}

.button_outline_primary {
    @apply border-neutral-500
    hover:border-violet-500
    focus:border-violet-500
    active:border-violet-900
    disabled:border-neutral-300 disabled:text-neutral-500;
}

.button_link {
    @apply inline-flex items-center p-0 text-primary-violet text-base leading-5 font-semibold gap-x-2.5
    hover:text-violet-500
    focus:text-violet-500
    active:text-violet-900;
}

.button_link__dark {
    @apply text-violet-500
    hover:text-violet-300
    focus:text-violet-300
    active:text-violet-700;
}

.button_link .icon svg {
    @apply transition-colors;
}

.button_link:hover .icon svg,
.button_link:focus .icon svg {
    @apply fill-violet-500;
}

.button_link:active .icon svg {
    @apply fill-violet-900;
}

.button_link_external {
    @apply gap-x-0.5;
}

.button_no-spacing {
    @apply p-0 m-0;
}

.button_counter {
    @apply p-1 bg-neutral-100
    hover:bg-violet-700
    focus:text-violet-700
    disabled:bg-neutral-100;
}

.button_counter .icon {
    @apply block;
}

.button_counter .icon svg {
    @apply transition-colors duration-300 scale-150
    hover:fill-white;
}

.button_counter:disabled .icon svg {
    @apply fill-neutral-400;
}

.button_white {
    @apply text-white;
}

.button_violet {
    @apply text-primary-violet !important;
}

.button_violet_500 {
    @apply text-violet-500 !important;
}

.button_neutral {
    @apply text-neutral-700 !important;
}

.button_neutral_600 {
    @apply text-neutral-600 !important;
}

.button_sm {
    @apply px-6 py-2.5 text-sm;
}

.button_md {
    @apply button-medium px-8 py-3.5;
}

.button_lg {
    @apply px-8 py-3.5 text-[15px] font-[600] leading-5
    lg:px-10 lg:py-4 lg:text-[17px] lg:leading-6;
}

.section_feature-links .button_lg, .section_text-block .button_lg {
    @apply lg:px-14;
}

.button_square {
    @apply p-4;
}

.button_block {
    @apply block w-full;
}

.button_bounce {
    @apply animate-bounce;
}

.button_mt-8 {
    @apply mt-8;
}

.button_shadow {
    box-shadow: 0 3.43px 2.75px 0 rgba(35, 37, 87, 0.0093),
    0 8.69px 6.95px 0 rgba(35, 37, 87, 0.0133),
    0 17.72px 14.18px 0 rgba(35, 37, 87, 0.0167),
    0 36.5px 29.2px 0 rgba(35, 37, 87, 0.0207);
}

.button_video {
    @apply relative pl-[64px] pr-0
        1024:pl-[80px];
}
.button_video .icon {
    @apply absolute left-[16px] top-1/2 -translate-y-1/2 w-[24px] h-[24px]
        768:left-[15px] 768:w-[26px] 768:h-[26px]
        1024:left-[24px] 1024:w-[32px] 1024:h-[32px];
}
.button_video .icon svg {
    @apply absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 w-full h-full;
}
.button_video.button_animate .icon:before, .button_video.button_animate .icon:after {
    @apply content-[''] absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 w-full h-full bg-violet-700 rounded-full -z-[1];
    animation: ripple 1.5s linear infinite;
}
.button_video.button_animate .icon:before {
    animation-delay: 0s;
}
.button_video.button_animate .icon:after {
    animation-delay: 0.75s;
}

@keyframes ripple {
    0% {
        transform: translate(-50%, -50% ) scale(1);
        opacity: 0.5;
    }
    25% {
        transform: translate(-50%, -50% ) scale(1.5);
        opacity: 0.2;
    }
    50% {
        transform: translate(-50%, -50% ) scale(2);
        opacity: 0.1;
    }
    100% {
        transform: translate(-50%, -50% ) scale(2.5);
        opacity: 0.0;
    }
}
