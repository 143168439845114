.reviews-item img {
    @apply w-[120px]
        sm:w-[140px];
}

.reviews_theme_home-badges .reviews-item .picture {
    @apply relative w-[120px] h-[120px]
        xl:w-[160px] xl:h-[160px];
}

.reviews_theme_home-badges .reviews-item .picture img {
    @apply absolute h-full w-auto top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
}