.list {
    @apply flex flex-col;
}

.list_row {
    @apply flex-row;
}

.list_gap_2 {
    @apply gap-2;
}
.list_gap_4 {
    @apply gap-4;
}
.list_gap_8 {
    @apply gap-8;
}

.list_ethical-tracking .list__item {
    @apply flex-col items-center
        md:flex-row md:items-start;
}

.list_faq {
    @apply [&:not(:last-of-type)]:mb-12;
}

.list_pricing-faq {
    @apply mt-8 480:mt-12;
}

.list_subnav {
    @apply grid grid-cols-1
        xl:grid-cols-2 xl:gap-4;
}
.list_subnav .nav-link {
    @apply flex gap-4 p-4 font-normal transition-colors duration-300 rounded-lg
        hover:bg-neutral-100
        active:bg-violet-100;
}
.list_subnav .nav-link:active .heading {
    @apply text-primary-violet;
}

.list_divider_dot .list__item {
    @apply [&:not(:last-of-type)]:after:content-['•'] [&:not(:last-of-type)]:after:mr-2 [&:not(:last-of-type)]:after:text-neutral-450;
}

.list_bordered .list__item {
    @apply items-center gap-x-2 px-6 py-4 text-primary-violet border-2 border-neutral-300 rounded transition-colors duration-300
        hover:bg-violet-100 hover:border-violet-100
        focus:bg-violet-100 focus:border-violet-100;
}
.list_type_disc-native {
    @apply list-disc list-inside;
}
.list_type_disc-native .list__item {
    display: list-item;
}
.list_type_disc .list__item:before {
    @apply content-['•'] inline ml-2;
}

.list_numbered {
    @apply list-decimal ml-5
        marker:font-[600];
}
