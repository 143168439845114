.nav-link {
    @apply mobile-body-3 768:desktop-body-3 transition-colors duration-300 text-neutral-900
        hover:text-violet-500
        focus:text-violet-500
        active:text-violet-900;
}

.nav-link_neutral_700 {
    @apply text-neutral-700;
}

.footer-nav .nav-link {
    @apply leading-5;
}
