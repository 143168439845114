.divider {
    @apply inline;
}

.divider_vertical {
    @apply border-r border-neutral-500;
}

.divider_horizontal {
    @apply border-t border-neutral-300;
}

.divider_solid {
    @apply border-solid;
}
.divider_dotted {
    @apply border-dotted;
}

.divider_green_700 {
    @apply border-green-700;
}
.divider_violet_700 {
    @apply border-violet-700;
}
.divider_red_700 {
    @apply border-red-700;
}
.divider_yellow_700 {
    @apply border-yellow-700;
}

.divider_about-us {
    @apply block mx-auto mb-4 border-t-4 rounded-full w-14;
}

.divider_error_page {
    @apply border-r !ml-24p !mr-24p border-neutral-700 mx-auto h-12p;
}