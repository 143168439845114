.tabs {
    @apply flex relative px-6 mx-auto scroll-smooth
        [&::-webkit-scrollbar]:w-0 [&::-webkit-scrollbar]:bg-transparent;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

.tabs_center {
    @apply justify-start
        lg:justify-center;
}

.tabs_mb_8 {
    @apply mb-8;
}

.tabs_overflow-x {
    @apply overflow-x-auto;
}

.tabs_border-b {
    @apply after:content-[''] after:absolute after:w-full after:h-px after:bottom-0 after:left-0 after:bg-neutral-300 after:-z-10;
}

.tabs_rounded {
    @apply bg-violet-50 border-4 border-white w-fit rounded-full p-0;
    box-shadow: 0 3.5px 3px 0 rgba(35, 37, 87, 0.01),
        0 9px 7px 0 rgba(35, 37, 87, 0.0133),
        0 18px 14px 0 rgba(35, 37, 87, 0.018),
        0 36px 30px 0 rgba(35, 37, 87, 0.02),
        0 -12px 30px 0 rgba(35, 37, 87, 0.02);
}

.tabs_fluid {
    @apply max-w-full;
}

.tabs_divider .tab-button:not(.active, .active + .tab-button, :first-child):before {
    @apply content-[''] absolute bg-violet-300 top-1/2 z-0 w-[1px] h-[80%] left-[0px] -translate-y-1/2;
}