.container_wide {
    @apply mx-6 rounded-2xl
    xl:max-w-[1288px]
    lg:max-w-[940px] lg:px-20
}

.container_no-spacing {
    @apply md:p-0 md:m-0;
}

.container_bg-neutral_100 {
    @apply bg-neutral-100;
}

.container_bg-neutral_900 {
    @apply bg-neutral-900;
}

.container {
    @apply 768:px-32p 1024:px-42p 1280:px-76p
}
