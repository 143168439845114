.paragraph {
    @apply text-sm text-neutral-900
        lg:text-base;
}

.paragraph_lg {
    @apply text-base
        1024:text-lg;
}
.paragraph_lg-custom {
    @apply text-base
        1024:text-lg;
}
.paragraph_sm {
    @apply text-[.875rem] leading-4
        lg:leading-5;
}
.paragraph_xs {
    @apply text-[.75rem] leading-5;
}

.paragraph_semibold {
    @apply font-semibold;
}

.paragraph_white {
    @apply text-white;
}
.paragraph_violet_300 {
    @apply text-violet-300;
}
.paragraph_violet_900 {
    @apply text-violet-900;
}
.paragraph_neutral_300 {
    @apply text-neutral-300;
}
.paragraph_neutral_500 {
    @apply text-neutral-500;
}
.paragraph_neutral_700 {
    @apply text-neutral-700;
}
.paragraph_red_700 {
    @apply text-red-700;
}
.paragraph_red_900 {
    @apply text-red-900;
}
.paragraph_text-center {
    @apply text-center;
}
.paragraph_text-left {
    @apply text-left;
}

.paragraph_mt_4 {
    @apply mt-4;
}

.paragraph_mb_4 {
    @apply mb-4;
}

.paragraph_mb_8 {
    @apply mb-8;
}

.paragraph_my_4 {
    @apply my-4;
}

.paragraph_overflow-anywhere {
    /* tailwind still doesn't have it  */
    overflow-wrap:anywhere;
}

.paragraph .icon {
    @apply mr-2;
}
.paragraph img {
    @apply inline ml-2;
}
