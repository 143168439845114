.legal {
    @apply pb-16;
}
.legal .h2  {
    @apply mb-6;
}
.legal .h3 {
    @apply mt-8 mb-4;
}
.legal .h4 {
    @apply mt-6 mb-4;
}

.legal .paragraph {
    @apply text-neutral-700 mb-2;
}

.legal_list_disk ul {
    @apply mt-4 list-disc list-inside pl-3;
}

.legal ul li,
.legal ol li {
    @apply text-neutral-700 [&:not(:last-of-type)]:mb-4;
}

.legal ul li > ol {
    @apply pl-7 mt-2;
}

.legal strong {
    @apply text-neutral-900;
}