.anchor-nav {
    @apply mb-8 p-4 border rounded text-[0.875rem] leading-5 order-first border-neutral-400
        lg:p-0 lg:border-0 lg:order-last;
}

.anchor-nav_sticky {
    @apply lg:h-[calc(100vh-60px)] lg:sticky lg:top-8 lg:overflow-auto;
}

.anchor-nav.collapsed > .list {
    @apply hidden
        lg:flex;
}

.anchor-nav .button {
    @apply w-full flex items-center justify-between cursor-pointer
        lg:mb-6 lg:cursor-default;
}
.anchor-nav > .button .icon {
    @apply transition-transform duration-300
        lg:hidden;
}

.anchor-nav .list {
    @apply mt-6
}

.anchor-nav .list__item {
    @apply text-neutral-700 underline underline-offset-4 decoration-dotted decoration-neutral-500;
}